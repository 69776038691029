@mixin map_canvas {
  width: 320px;
  height: 320px;
}
@mixin store-locator-mobile-v1 {
  a:hover {
    text-decoration: none;
  }
  h2.store_locator {
    margin-top: 50px;
  }
  .map-marker {
    /* background-image: url($template_base_path + '/store_locator_v1/result-pin.png'); */
    width: 29px;
    height: 44px;
    color: #FFF;
    text-align: center;
    padding-top: 10px;
    font-weight: bold;
  }
  .map_container,
  .locations_table_panel {
    margin-#{$ldirection}: 0;
    margin-#{$rdirection}: 0;
  }
  .door_row {
    @include swap_direction (padding, 10px);
    border-bottom: 1px solid #000;
  }
  .door_row:nth-last-child(1),
  .door_row:nth-child(10) {
    border: none;
  }
  .store_name {
    a {
      color: inherit;
      text-decoration: none;
    }
    &.actual_store_name a {
      font-weight: bold;
    }
  }
  .results-count {
    display: none;
  }
  .button {
    width: 100%;
    &.more-results-button {
      margin-top: 0;
    }
    &.geo_search {
      border: 1px solid #000;
      background: none;
      color: #000;
      &:hover {
        background: none;
      }
    }
    &.geo_icon {
      position: relative;
    }
  }
  .event-image {
    float: $ldirection;
  }
  .search_input {
    background: white;
    border: 1px solid #000;
    width: 100%;
  }
  ul.error_messages li {
    color: red;
    @include swap_direction (margin, 0 0 5px);
    display: inline-block;
    &.inactive {
      display: none;
    }
  }
  .location_info h1 {
    color: #000;
    font-size: 14px;
    line-height: normal;
    @include swap_direction (margin, 10px 0);
  }
  .doors_body {
    font-size: 14px;
    &.store_address {
      color: #000;
    }
  }
  a.view-details {
    font-weight: bold;
  }
  .map_canvas {
    @include map_canvas;
  }
  .map_container {
    @include map_canvas;
    position: relative;
    .scroll-overlay {
      width: 55px;
      height: 320px;
      position: absolute;
      top: 0;
      #{$rdirection}: 0;
      z-index: 1000;
      opacity: 0.9;
    }
  }
  .search_container {
    border-bottom: 1px solid #000;
    margin-bottom: 15px;
  }
  input.search_input {
    width: 100%;
    margin-bottom: 20px;
  }
  select.country {
    width: 100%;
    @include swap_direction (padding, 5px 13px);
    @include swap_direction (margin, 10px 0);
  }
  .locator_content {
    label {
      font-weight: normal;
    }
    .button {
      float: $rdirection;
    }
  }
  .results_header_wrapper {
    clear: both;
    padding-bottom: 5px;
    display: inline-block;
    width: 100%;
  }
  .event-details {
    @include swap_direction (margin, 10px 0);
  }
  .store_extra {
    @include swap_direction (margin, 10px 0 0);
    clear: both;
  }
  .results_header_wrapper {
    @include swap_direction (padding, 0);
    @include swap_direction (margin, 0);
  }
  .doors_results_header {
    color: #000;
    @include swap_direction (padding, 10px);
    margin-bottom: 10px;
    .query {
      color: #000;
    }
  }
  input[type="text"].error,
  select.error {
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
  }
  .gm-style-iw,
  .gm-style-iw > div {
    overflow: hidden !important;
    height: auto !important;
    width: auto !important;
  }
}
.device-mobile {
  .store-locator-mobile-v1 {
    @include store-locator-mobile-v1;
  }
}

.store-locator-mobile-popup-v1 {
  @include store-locator-mobile-v1;
  > * {
    margin-#{$ldirection}: 0;
    margin-#{$rdirection}: 0;
  }
  .event-popup-header {
    color: #000;
    @include swap_direction (margin, 12px 0 0);
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
  }
  .event-popup-content {
    color: #000;
    font-size: 14px;
    @include swap_direction (margin, 10px 0 10px 10px);
    min-height: 125px;
  }
  /* ADD EVENT TO CALENDAR */

  .addthisevent-drop {
    background: none repeat scroll 0 0 #5DBA98;
    border: 0 none;
    color: #FFFFFF !important;
    display: inline-block;
    font-family: #000;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 23px;
    @include swap_direction (padding, 4px 10px 3px);
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    &:hover {
      background: none repeat scroll 0 0 #44A07E;
      text-decoration: none;
    }
  }
  /* END ADD EVENT TO CALENDAR */
}

.store-locator .locator_error_messages li {
  color: $color-red;
  margin-bottom: 15px;
}

.store-locator__main .gm-style-iw + div {
  display: none;
}

.store-locator--mobile {
  .query {
    font-weight: bold;
  }
  .count {
    font-weight: bold;
  }
  .geo_search {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }
  .cart-caret {
    /* @include arrow(top, null, $color-white, $color-gray, 20px); - borders just don't look right... */
    background: url('/media/images/background-images/background-image2.png') no-repeat 0 0;
    position: absolute;
    top: -11px;
    #{$ldirection}: 45px;
    width: 22px;
    height: 12px;
  }
  .mobile-list {
    .icon-hamburger {
      margin-#{$ldirection}: 0;
    }
    .icon-hamburger-bullets:before {
      content: "\22EE";
      float: $ldirection;
      font-size: 31px;
      font-weight: bold;
      line-height: 26px;
    }
  }
}